<template>
  <div>
    <my-nav-bar
      title="选择品牌"
      left-text="返回"
      left-arrow
    />
    <van-search v-model="listQuery.brand_name" class="search-bar" placeholder="请输入品牌名称" clearable @search="onSearch" />
    <div class="app-main-search-container">
      <van-list
        ref="list"
        v-model="loading"
        :finished="finished"
        @load="getList"
      >
        <van-empty v-if="showEmpty" description="暂无数据" />
        <van-cell v-for="brand in list" :key="brand.id" :title="brand.name" is-link center @click="handleSelect(brand)" />
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getBrands } from '@/api/sales_ranking'
export default {
  components: { myNavBar },
  data() {
    return {
      listQuery: {
        page: 0,
        limit: 20,
        brand_name: '',
        store_id: this.$route.query.store_id
      },
      list: [],
      loading: false,
      finished: false,
      showEmpty: false
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getBrands(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
      }).catch(() => {
        this.showEmpty = this.list.length === 0
        this.finished = true
      })
    },
    reload() {
      this.listQuery.page = 0
      this.list = []
      this.finished = false
      this.showEmpty = false
      this.$refs.list.check()
    },
    onSearch() {
      this.reload()
    },
    handleSelect(brand) {
      this.$eventBus.$emit('select-brand', brand)
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
  .search-bar {
    position: fixed;
    top: 46px;
    left: 0;
    right: 0;
    z-index: 99;
  }
</style>
