var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "选择品牌",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('van-search', {
    staticClass: "search-bar",
    attrs: {
      "placeholder": "请输入品牌名称",
      "clearable": ""
    },
    on: {
      "search": _vm.onSearch
    },
    model: {
      value: _vm.listQuery.brand_name,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "brand_name", $$v);
      },
      expression: "listQuery.brand_name"
    }
  }), _c('div', {
    staticClass: "app-main-search-container"
  }, [_c('van-list', {
    ref: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (brand) {
    return _c('van-cell', {
      key: brand.id,
      attrs: {
        "title": brand.name,
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleSelect(brand);
        }
      }
    });
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }